import React, { memo } from 'react';
import { StaticQuery, graphql, Link } from 'gatsby';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { ContainerDiv } from '@components/Container.styled';
import Paragraph from '@components/Typography/Paragraph';
import { MOBILE_BREAKPOINT, DEVICE } from '@assets/const';

const { sm, md, lg } = MOBILE_BREAKPOINT;
const { MD } = DEVICE;

const Container = styled(ContainerDiv)`
  display: block;
  z-index: 1;
  padding: 0;
`;

const Flex = styled.div`
  display: flex;
  margin: 1rem;
  padding: 1rem;
  border: 1px solid var(--secondary-dark-background);
  box-shadow: 0 0 10px black;
  height: 140px;
  background-color: var(--secondary-dark-background);
`;

const SectionTitle = styled.p`
  font-family: 'Cormorant Garamond', serif;
  font-size: 2.5rem;
  text-align: center;

  @media ${MD} {
    font-size: 3.5rem;
  }
`;

const FlexColumnSpaceBetween = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const VerticalLine = styled.div`
  width: 2px;
  background: #bd8604;
  margin: 0 1rem 0 0;
`;

const NewsSlider = ({ data }) => {
  const { nodes: articles } = data.allMdx;

  const settings = {
    dots: false,
    autoplay: true,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    pauseOnFocus: true,
    lazyLoad: 'ondemand',
    responsive: [
      {
        breakpoint: lg,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: md,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: sm,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const renderSliderItems = () => {
    return articles.map((item) => {
      return (
        <Link to={`aktualnosci/${item.slug}`} key={item.id}>
          <Flex>
            <VerticalLine />
            <FlexColumnSpaceBetween>
              <Paragraph mtop={0.5}>{item.frontmatter.title}</Paragraph>
              <Paragraph fsize={0.8} gray mbottom={0.5}>
                {item.frontmatter.date}
              </Paragraph>
            </FlexColumnSpaceBetween>
          </Flex>
        </Link>
      );
    });
  };

  return (
    <Container>
      <SectionTitle>Najnowsze wpisy</SectionTitle>
      <Slider {...settings}>{renderSliderItems()}</Slider>
    </Container>
  );
};

export default memo(function SliderData() {
  return (
    <StaticQuery
      query={graphql`
        query {
          allMdx(sort: { fields: frontmatter___date, order: DESC }, limit: 5) {
            nodes {
              frontmatter {
                date
                title
                shortDescription
              }
              id
              slug
            }
          }
        }
      `}
      render={(data) => <NewsSlider data={data} />}
    />
  );
});

NewsSlider.propTypes = {
  data: PropTypes.shape({
    allMdx: PropTypes.shape({
      nodes: PropTypes.arrayOf(
        PropTypes.shape({
          frontmatter: PropTypes.shape({
            date: PropTypes.string,
            title: PropTypes.string,
            shortDescription: PropTypes.string,
          }),
          id: PropTypes.string,
          slug: PropTypes.string,
        })
      ),
    }),
  }).isRequired,
};

NewsSlider.defaultProps = {};
