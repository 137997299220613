import { DEVICE } from '@assets/const';
import GradientBtn from '@components/Buttons/GradientBtn';
import Container from '@components/Container';
import NewsSlider from '@components/NewsSlider';
import Layout from '@components/Layout';
import LayoutTopSection from '@components/LayoutTopSection';
import RectangleLeftBottom from '@images/bottom.svg';
import HomePage from '@images/pages/home-page.svg';
import RectangleFooterHome from '@images/RectangleFooterHome.svg';
import RectangleLeftTop from '@images/top.svg';
import React from 'react';
import styled from 'styled-components';

const { SM, MD, LG } = DEVICE;

const dropShadow = `drop-shadow(12px 15px 5px rgba(0, 0, 0, 0.5))`;

const ContainerColor = styled.div`
  background: var(--dark-background);
  padding: 2rem 0 0 0;
  position: relative;

  &::before {
    content: '';
    background-image: url(${RectangleFooterHome});
    background-repeat: no-repeat;
    position: absolute;
    display: inline-block;
    width: 100%;
    height: 150px;
    top: -150px;
    z-index: -5;

    @media ${SM} {
      height: 250px;
      top: -250px;
    }

    @media ${MD} {
      height: 450px;
      top: -450px;
      padding: 2rem 0 0 0;
    }
  }

  &::after {
    @media ${MD} {
      content: '';
      background-image: url(${RectangleLeftTop});
      background-repeat: no-repeat;
      position: absolute;
      display: inline-block;
      height: 350px;
      width: 100%;
      top: -350px;
      z-index: -4;
      -webkit-filter: ${dropShadow};
      filter: ${dropShadow};
    }

    @media ${LG} {
      height: 550px;
      top: -550px;
    }
  }
`;

const ContainerImg = styled.div`
  @media ${MD} {
    background-image: url(${RectangleLeftBottom});
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    display: inline-block;
    height: 350px;
    width: 100%;
    top: 0;
    z-index: 1;
    -webkit-filter: ${dropShadow};
    filter: ${dropShadow};
  }

  @media ${LG} {
    height: 550px;
  }
`;

const Home = () => {
  const renderContent = () => <GradientBtn url="/o-nas" text="Zobacz więcej" />;

  const renderSiteImg = () => <img src={HomePage} alt="" width="100%" />;
  const nbsp = '\u00A0';

  return (
    <Layout home>
      <Container>
        <LayoutTopSection
          title={`Rozwiązania szyte na${nbsp}miarę`}
          subtitle={`Oferujemy kompleksowy wachlarz usług dla Klienta indywidualnego oraz${nbsp}biznesowego.`}
          content={renderContent()}
          rightSide={renderSiteImg()}
        />
      </Container>
      <ContainerColor>
        <ContainerImg />
        <Container>
          <NewsSlider />
        </Container>
      </ContainerColor>
    </Layout>
  );
};

export default Home;
